import React, {Component} from 'react';
import {Container, Row, Col, Collapse} from 'reactstrap'
import SignUpForm from 'components/SignUpForm'
import {Link} from "gatsby";
import Calendar from 'components/Calendar'
import {StaticQuery, graphql} from "gatsby"
import moment from 'moment'
import star from "images/star.svg"

class Leeds extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCalendar: true,
            collapses: {
                collapseA: true,
                collapseB: false,
                collapseC: false,
                collapseD: false,
            },
            // coronaMessageOpen: true,
        }
    }

    // closeCoronaMessage = () => {
    //     this.setState({
    //         coronaMessageOpen: false
    //     })
    // }

    componentDidMount() {
        setTimeout(() => {
            this.setState({showCalendar: true})
        }, 1000)
    }

    loadEvents = (events) => {
        let futureEvents = [];

        for (var key in events) {
            if (events[key].node.siteId === 'leeds') {
                for (var eventKey in events[key].node.items) {
                    if (moment().diff(events[key].node.items[eventKey].start, 'hours') <= 0 && events[key].node.items[eventKey].active) {
                        futureEvents.push(events[key].node.items[eventKey]);
                    }
                }
            }
        }

        const eventDates = futureEvents.map(event => moment(event.start));
        const minDate = moment.min(eventDates - 1 );
        const maxDate = moment.max(eventDates + 1);

        return (
        <Calendar
        events={futureEvents}
        defaultDate={moment("2025-11-01", "YYYY-MM-DD")}
        visibleRange={{
          start: moment("2025-11-01", "YYYY-MM-DD"),
          end: moment("2025-11-30", "YYYY-MM-DD"),
        }}
        validRange={{
          start: moment("2025-11-01", "YYYY-MM-DD"),
          end: moment("2025-11-30", "YYYY-MM-DD"),
        }}
      />
        )

    };

    toggleAccordion = (id, e) => {
        let collapses = {...this.state.collapses};
        const collapse = id;

        for (var key in collapses) {
            if (collapses.hasOwnProperty(key) && collapse !== key) {
                collapses[key] = false;
            }
        }

        collapses[collapse] = true;

        this.setState({collapses});
    };

    render() {
        const {phase} = this.props;
        const {showCalendar} = this.state;

        const collapses = {...this.state.collapses};

        return (
            <>
                <div className="section-intro">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                {phase === 'registration' &&
                                <h1 className="text-center">THE MORMONS ARE COMING TO LEEDS</h1>
                                }

                                {phase === 'open' &&
                                <h1>How to buy your tickets for LEEDS</h1>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>

                {phase === 'open' &&
                <>
                    <div className="section-intro section-sign-up">
                        <Container>
                            <Row>
                                <Col xs={12}>

                                    <div className="section-intro__wrapper">
                                        <div className="section-intro__wrapper-header">
                                            <h2>TICKET TIPS</h2>
                                        </div>
                                        <div className="section-intro__wrapper-body">
                                            <h3>WE KNOW IT CAN BE HARD SOMETIMES FINDING THE RIGHT TICKET SO HERE ARE A
                                                FEW HANDY TIPS:</h3>

                                            <ul>
                                                <li>SIGN-UP <a href="#footer">HERE</a> to receive latest news including
                                                    priority seat
                                                    releases
                                                </li>
                                                <li>For a wider range of prices and seats, try midweek performances</li>
                                                {/*<li>For access performances <a href="#section-accordion" onClick={(e) => this.toggleAccordion('collapseB', e)}>click here</a></li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="section-accordion" id="section-accordion">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                {showCalendar &&
                                        <>
                                            <h2 className='py-3'>ONLINE BOOKINGS</h2>
                                            <ul className="keyList">
                                                    <li>
                                                        <div className="keyList__key">KEY:</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__available">AVAILABLE</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__limited">LIMITED</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__sold-out">SOLD OUT</div>
                                                    </li>
                                                </ul>

                                            {this.loadEvents(this.props.events.allEventsJson.edges)}
                                        </>
                                    }
                                    <div className="section-accordion__wrap">
                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseA === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseA', e)}>
                                            CONTACT
                                        </button>
                                  <Collapse
                                                                             isOpen={collapses.collapseA}
                                                                             onEntering={this.onEntering}
                                                                             onEntered={this.onEntered}
                                                                             onExiting={this.onExiting}
                                                                             onExited={this.onExited}
                                                                             className={`section-accordion__wrap-content accordion-content has-inner a ${collapses.collapseA === true ? 'active' : ''} dublin`}>
                                             <div className="section-accordion__wrap-content-inner">
                                             <p>For queries relating to your booking at The Grand, please see their <a href="https://leedsheritagetheatres.com/faq/" target="_blank" rel="noopener noreferrer">FAQs</a>&nbsp;or contact them on 0113 243 0808 or at&nbsp;<a href="mailto:boxoffice@leedsheritagetheatres.com" target="_blank" rel="noopener noreferrer"><strong>boxoffice@leedsheritagetheatres.com</strong></a>.</p>
                                 
                                                                             </div>
                                                                          
                                                                         </Collapse>

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseD === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseD', e)}>
                                            YOUR VISIT
                                        </button>
                                        <Collapse isOpen={collapses.collapseD}
                                                  className={`section-accordion__wrap-content accordion-content d ${collapses.collapseD === true ? 'active' : ''}`}>
                                            <p>Plan your visit to&nbsp;<a href="https://leedsheritagetheatres.com/leeds-grand-theatre/plan-your-visit/" target="_blank" rel="noopener noreferrer">Leeds Grand Theatre</a>.</p>
                                        </Collapse>

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseC === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseC', e)}>
                                            ACCESS
                                        </button>
                                        <Collapse isOpen={collapses.collapseC}
                                                  className={`section-accordion__wrap-content accordion-content has-inner c ${collapses.collapseC === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">
                                            <p>Please visit&nbsp;<a href="https://leedsheritagetheatres.com/access/" target="_blank" rel="noopener noreferrer">here</a>&nbsp;for more details.</p>
                                            </div>
                                        </Collapse>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
                }

                {phase === 'registration' &&
                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap">
                                    <h2 className={`h3`}>SIGN UP NOW AND GET ACCESS TO THE PRESALE <br/>REGISTER NOW</h2>
                                    <SignUpForm
                                        className="sign-up-form"
                                        location="/leeds/"
                                        locationName="leeds"
                                        phase={phase}
                                        thankYouPage={true}
                                    />
                                    <p className="small-copy">
                                        By submitting your email address, you are agreeing to receive marketing
                                        emails about The Book of Mormon. You can opt out of receiving
                                        these emails at any time.
                                        <br/>To see full details of our privacy policy <Link
                                        to={`/leeds/privacy-policy/`}>click here</Link>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'presale' &&
                <div className="section-sign-up space-below">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap orange-border">
                                    <h3 className="mb-2">THE MORMONS ARE COMING TO LEEDS</h3>
                                    <div className="narrow">
                                        <p>&nbsp;</p>
                                        <p>Rescheduled performances will now take place at</p>
                                        <p>&nbsp;</p>
                                        <p className="big orange text-uppercase"><strong>Leeds Grand Theatre</strong></p>
                                        <p>&nbsp;</p>
                                        <p className="big orange text-uppercase"><strong>19 April – 7 May 2022</strong></p>
                                        <p>&nbsp;</p>
                                        <p>Sign up here to be the first to hear when tickets are released!</p>
                                        <p>&nbsp;</p>
                                        <p>Ticketholders for the previous run do not need to do anything. The theatre will contact you regarding the next steps.</p>
                                    </div>
                                    <SignUpForm
                                        className="sign-up-form"
                                        location="/leeds/"
                                        locationName="leeds"
                                        phase={phase}
                                        thankYouPage={true}
                                    />
                                    <p className="small-copy">
                                        By submitting your email address, you are agreeing to receive marketing
                                        emails about The Book of Mormon. You can opt out of receiving
                                        these emails at any time.
                                        <br/>To see full details of our privacy policy <Link
                                        to={`/leeds/privacy-policy/`}>click here</Link>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'open' &&
                <div className="section-show-info">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-show-info__wrap">
                                    <div className="row">
                                        <div className="section-show-info__wrap-map">
                                            <h2 className="d-lg-none">Show Info</h2>
                                            <div className={`map leeds`}>
                                            </div>
                                        </div>

                                        <div className="section-show-info__wrap-content">
                                            <div className="content">
                                                <h2 className="d-none d-lg-block">Show Info</h2>
                                                <h3>FROM 11 – 29 NOV 2025</h3>
                                                <p>Leeds Grand Theatre<br/>
                                                    46 New Briggate, Leeds, LS1 6NU
                                                </p>

                                                <a className="btn btn-google-maps"
                                                   href="https://g.page/LeedsGrandTheatre?share"
                                                   target="_blank"
                                                   rel="noreferrer noopener">
                                                    <span className="sr-only">Leeds Grand Theatre Map</span>
                                                </a>

                                                <h3>PERFORMANCE TIMES</h3>
                                                <p>Mon – Sat 7.30pm</p>
                                                <p>Fri & Sat 2.30pm</p>
                                                <h3 className="mt-2">Running Time</h3>
                          <p>2 hours 20 minutes, including interval</p>
                          <br />
                          <p>
                            <strong>Parental advisory:</strong> Explicit
                            language.
                          </p>
                          <p>
                            <strong>Please note:</strong>&nbsp;This production
                            contains strobe lighting, flashing lights and
                            gunshots.&nbsp;
                          </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {/*<div className={`coronaMsg ${!this.state.coronaMessageOpen && 'd-none'}`}>*/}

                {/*    <div className="coronaMsg--content">*/}

                {/*        <p>Under the direction of the UK Government, theatre shows playing to more than **** people will suspend all performances immediately in support of the health and well-being of the theatregoing public, as well as those who work in the theatre industry. Performances will commence the week of ** ***** ****</p>*/}

                {/*        <p>Those holding tickets for performances until ** ***** **** should contact their point of purchase for refunds and exchanges.</p>*/}

                {/*        <span className="coronaMsg--closeBtn" role="button" tabIndex={0} onKeyPress={this.closeCoronaMessage} onClick={this.closeCoronaMessage}>X</span>*/}

                {/*    </div>*/}
                {/*</div>*/}
            </>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
        query {
            allEventsJson {
                edges {
                    node {
                        siteId
                        items {
                            url
                            start
                            title
                            className
                            active
                            type
                        }
                    }
                }
            }
        }`}
        render={data => (
            <Leeds events={data}
                        multiLang={props.multiLang}
                        phase={props.phase}
                        siteId={props.siteId}
                        language={props.language}
            />
        )}
    />
)